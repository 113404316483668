import ApiService from "../../services/api-service";
import AddButton from "../../components/AddButton";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";

export default {
    name: "PlatformList",
    components: {DeleteButton, EditButton, AddButton},
    data() {
        return {
            items: [],
            fields: [
                {key: "ordinal", label: "Порядок"},
                {key: "shortName", label: "Краткое название"},
                {key: "name", label: "Полное название"},
                {key: "availableInRp", label: "Доступна в Retro Play"},
                {key: "editDelete", label: ""}
            ]
        }
    },
    methods: {
        getData() {
            ApiService.get("admin/platform/list").then(response => {
                this.items = response.data
            })
        }
    },
    mounted() {
        this.getData()
    }
}