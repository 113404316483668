import ApiService from "../../services/api-service";

export default {
    name: "AddRetroPlayGame",
    data() {
        return {
            loading: false,
            platforms: [],
            newGame: {
                gameName: null,
                platformId: null,
                genreId: null
            },
            genres: [],
            selectedGame: {
                id: null,
                gameName: null,
                genreId: null,
                editMode: false
            },
            showItems: false
        }
    },
    computed:{
        canAdd: function(){
            return this.newGame.gameName && this.newGame.genreId
        },
        canClear: function(){
            return this.newGame.gameName || this.newGame.genreId
        }
    },
    methods: {
        getData() {
            this.loading = true
            ApiService.get("admin/rp-add-game/list").then(response => {
                response.data.forEach(platform => {
                    platform.games.forEach(game => {
                        game.editMode = false
                    })
                })
                this.platforms = response.data
                document.getElementById("new-game-name").focus()
            }).finally(() => {
                this.loading = false
            })
        },
        addNewGame(platformId){
            this.loading = true
            this.newGame.platformId = platformId
            console.log(this.newGame)
            console.log(platformId)
            ApiService.post("admin/rp-add-game/create", this.newGame).then(() => {
                this.clear()
                this.getData()
            }).finally(() => {
                this.loading = false
            })
        },
        clear(){
            this.newGame.gameName = null
            this.newGame.platformId = null
            this.newGame.genreId = null
        },
        deleteGame(){
            this.loading = true
            ApiService.delete(`admin/rp-add-game/delete/${this.selectedGame.id}`).then(() => {
                this.getData()
            }).finally(() => {
                this.loading = false
            })
        },
        editMode(item){
            console.log(item)
            item.editMode = true
        },
        editGame(item){
            item.editMode = false
            this.loading = true

            let game = {
                id: item.id,
                gameName: item.gameName,
                platformId: item.platformId,
                genreId: item.genreId
            }

            ApiService.put("admin/rp-add-game/update", game).then(() => {
                this.getData()
            }).finally(() => {
                this.loading = false
            })
        },
        cancelEditMode(item){
            item.editMode = false
        }
    },
    mounted() {
        this.getData()
        ApiService.get("items/genres").then(response => {
            this.genres = response.data
        })
    }
}