import ApiService from "../../services/api-service";

export default {
    name: "AddRetroPlayGame",
    data() {
        return {
            loading: false,
            platforms: []
        }
    },
    methods: {
        getData() {
            this.loading = true
            ApiService.get("retro-play/list").then(response => {
                this.platforms = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    mounted() {
        this.getData()
    }
}