import SimpleForm from "../../components/SimpleForm";

export default {
    name: "PlatformEdit",
    components: {SimpleForm},
    data() {
        return {
            platform: {
                id: null,
                ordinal: null,
                name: null,
                shortName: null,
                availableInRp: false
            }
        }
    }
}