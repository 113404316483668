import VodIcon from "../../components/VodIcon"
import ApiService from "../../services/api-service"

export default {
    name: "RpProgress",
    components: {VodIcon},
    data() {
        return {
            loading: false,
            items: [],
            fields: [
                {key: "index", label: "№", sortable: true},
                {key: "gameName", label: "Название", sortable: true},
                {key: "platformName", label: "Платформа", sortable: true},
                {key: "genreName", label: "Жанр", sortable: true},
                {key: "statusName", label: "Статус", sortable: true},
                {key: "duration", label: "Время", sortable: true},
                {key: "pickedBy", label: "Выбрал", sortable: true},
                {key: "startDate", label: "Дата начала", sortable: true},
                {key: "endDate", label: "Дата завершения", sortable: true},
                {key: "streamNumber", label: "Стрим №", sortable: true},
                {key: "rate", label: "Рейтинг", sortable: true},
                {key: "difficulty", label: "Сложность", sortable: true},
                {key: "vodParts", label: "Ссылки на воды", tdClass: "vods"},
                {key: "comment", label: "Детали прохождения"}
            ]
        }
    },
    methods: {
        getData() {
            this.loading = true
            ApiService.get("retro-play/progress").then(response => {
                this.items = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    mounted() {
        this.getData()
    }
}