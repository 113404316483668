import { render, staticRenderFns } from "./MainMenu.vue?vue&type=template&id=57a60d97&scoped=true"
import script from "./main-menu.js?vue&type=script&lang=js&external"
export * from "./main-menu.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a60d97",
  null
  
)

export default component.exports