import Vue from "vue"
import VueRouter from "vue-router"
import Login from "./views/Login";
import Admin from "./views/Admin";
import Platform from "./views/admin/Platform";
import PlatformEdit from "./views/admin/PlatformEdit";
import PlatformList from "./views/admin/PlatformList";
import Genre from "./views/admin/Genre";
import GenreList from "./views/admin/GenreList";
import GenreEdit from "./views/admin/GenreEdit";
import ProjectList from "./views/admin/ProjectList";
import ProjectEdit from "./views/admin/ProjectEdit";
import Playthrough from "./views/admin/Playthrough";
import PlaythroughList from "./views/admin/PlaythroughList";
import PlaythroughEdit from "./views/admin/PlaythroughEdit";
import CompletedList from "./views/all/CompletedList";
import All from "./views/All";
import Statistics from "./views/all/Statistics";
import CompletedByPlatform from "./views/all/CompletedByPlatform";
import Logout from "./views/Logout";
import Register from "./views/Register";
import RetroPlay from "./views/RetroPlay";
import RpProgress from "./views/retroplay/RpProgress";
import RetroPlayAll from "./views/retroplay/RetroPlayList";
import AddRetroPlayGame from "./views/admin/AddRetroPlayGame";
import Fix from "./views/admin/Fix";
import Game from "./views/admin/Game";
import GameList from "./views/admin/GameList";
import GameEdit from "./views/admin/GameEdit";
import RggPlatformWidget from "./views/widget/RggPlatformWidget";

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/register",
        name: "Register",
        component: Register
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout
    },
    {
        path: "/",
        name: "All",
        component: All,
        children: [
            {
                path: "completed",
                name: "Completed",
                component: CompletedList
            },
            {
                path: "completed-by-platform",
                name: "CompletedByPlatform",
                component: CompletedByPlatform
            },
            {
                path: "statistics",
                name: "Statistics",
                component: Statistics
            },
        ]
    },
    {
        path: "/retro-play",
        name: "RetroPlay",
        component: RetroPlay,
        children: [
            {
                path: "progress",
                name: "RpProgress",
                component: RpProgress
            },
            {
                path: "all",
                name: "RetroPlayAll",
                component: RetroPlayAll
            }
        ]
    },
    {
        path: "/widget/rgg-platform/:seasonNumber",
        name: "RggPlatformWidget",
        component: RggPlatformWidget,
        props: true
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: {requiresAdmin: true},
        children: [
            {
                path: "playthrough",
                component: Playthrough,
                children: [
                    {
                        path: "",
                        name: "PlaythroughList",
                        component: PlaythroughList
                    },
                    {
                        path: "add",
                        name: "PlaythroughAdd",
                        component: PlaythroughEdit
                    },
                    {
                        path: "edit/:id",
                        name: "PlaythroughEdit",
                        component: PlaythroughEdit
                    }
                ]
            },
            {
                path: "add-rp-game",
                name: "AddRetroPlayGame",
                component: AddRetroPlayGame,
            },
            {
                path: "platform",
                component: Platform,
                children: [
                    {
                        path: "",
                        name: "PlatformList",
                        component: PlatformList
                    },
                    {
                        path: "add",
                        name: "PlatformAdd",
                        component: PlatformEdit
                    },
                    {
                        path: "edit/:id",
                        name: "PlatformEdit",
                        component: PlatformEdit
                    }
                ]
            },
            {
                path: "genre",
                component: Genre,
                children: [
                    {
                        path: "",
                        name: "GenreList",
                        component: GenreList
                    },
                    {
                        path: "add",
                        name: "GenreAdd",
                        component: GenreEdit
                    },
                    {
                        path: "edit/:id",
                        name: "GenreEdit",
                        component: GenreEdit
                    }
                ]
            },
            {
                path: "project",
                component: Genre,
                children: [
                    {
                        path: "",
                        name: "ProjectList",
                        component: ProjectList
                    },
                    {
                        path: "add",
                        name: "ProjectAdd",
                        component: ProjectEdit
                    },
                    {
                        path: "edit/:id",
                        name: "ProjectEdit",
                        component: ProjectEdit
                    }
                ]
            },
            {
                path: "game",
                component: Game,
                children: [
                    {
                        path: "",
                        name: "GameList",
                        component: GameList
                    },
                    {
                        path: "add",
                        name: "GameAdd",
                        component: GameEdit
                    },
                    {
                        path: "edit/:id",
                        name: "GameEdit",
                        component: GameEdit
                    }
                ]
            },
            {
                path: "fix",
                name: "Fix",
                component: Fix,
            }
        ]
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAdmin)) {
        if (localStorage.getItem("admin")
            && localStorage.getItem("token")) {
            next()
        } else {
            next({
                path: "/login",
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next()
    }
})

export default router
